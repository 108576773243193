<template>
  <div class="camera-view" ref="cameraContainer">
    <vs-popup :active.sync="showHelpModal" title="Instruções">
      <div class="text-center">
        <h2 class="text-lg font-semibold mb-2">{{titleInstruction}}</h2>
        <img src="@/assets/images/document/selfie_instruction.png" alt="document" class="mx-auto" style="max-width: 90px;"/>
        <p class="text-gray-600 mb-4">Coloque seu rosto dentro da área oval.</p>
        <vs-button @click="showHelpModal = false" class="px-4 py-2" color="success">
          OK, entendi
        </vs-button>
      </div>
    </vs-popup>

    <vs-popup :active.sync="showLandscapeWarning" title="Atenção" :button-close-hidden="true">
      <div class="text-center">
        <p class="text-gray-600 mb-4">A captura deve ser feita em modo retrato. Por favor, vire o dispositivo.</p>
      </div>
    </vs-popup>

    <video v-show="!capturedVideo && !showLandscapeWarning" ref="video" autoplay playsinline class="video"></video>
    <canvas v-show="!capturedVideo && !showLandscapeWarning" ref="overlay" class="overlay"></canvas>
    <p v-show="!capturedVideo && !showLandscapeWarning" class="instructions">{{ instructionText }}</p>
    <vs-button :disabled="!canCapture" v-show="!capturedVideo && !showLandscapeWarning" @click="nextInstruction" class="capture-button" color="primary" icon="camera">{{ buttonText }}</vs-button>
  </div>
</template>

<script>
export default {
  props: {
    videoRecorded: {
      type: Function,
      default: null,
    },
    retryUpload: {
      type: Function,
      default: null,
    },
    type: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      showHelpModal: true,
      showLandscapeWarning: false,
      capturedVideo: null,
      videoStream: null,
      mediaRecorder: null,
      recordedChunks: [],
      ovalWidth: null,
      ovalHeight: null,
      ovalX: null,
      ovalY: null,
      instructionText: 'Encaixe o seu rosto na área oval abaixo',
      currentInstructionIndex: -1,
      detectionActive: true,
      borderColor: 'white', // Default border color
      canCapture: true, // Enable capture button
      instructions: ['Olhe para a esquerda', 'Olhe para a direita', 'Olhe para cima', 'Olhe para baixo'],
      buttonText: 'Ok',
      uploadErrorFlag: false // Flag to indicate upload error
    };
  },
  computed: {
    titleInstruction() {
      return (this.type && (this.type === 'both' || this.type ==='liveness-video')) ?  'Prova de Vida' : 'Self (Foto da Face)'
    }
  },
  mounted() {
    this.startCamera();
    window.addEventListener('resize', this.handleOrientationChange);
    this.handleOrientationChange(); // Call once on mount to set initial orientation
  },
  methods: {
    async startCamera() {
      try {
        //const hasPermissions = await this.checkPermissions();
        //if (!hasPermissions) return;

        const constraints = {
          audio: false,
          video: {
            width: { ideal: 720 },
            height: { ideal: 1280 },
            facingMode: 'user'
          }
        };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        this.$refs.video.srcObject = stream;
        this.videoStream = stream;
        this.drawOverlay();
      } catch (err) {
        console.error('Error accessing the camera: ', err);
        if (err.name === 'NotAllowedError') {
          alert('Permissões de câmera ou microfone negadas. Verifique as permissões no navegador.');
        } else if (err.name === 'NotFoundError') {
          alert('Dispositivo de câmera não encontrado.');
        } else {
          alert('Erro ao acessar a câmera. Por favor, tente novamente.');
        }
      }
    },
    handleOrientationChange() {
      const isLandscape = window.innerWidth > window.innerHeight;
      this.isLandscape = isLandscape;

      this.ovalWidth = window.innerWidth * 0.8;
      this.ovalHeight = window.innerHeight * 0.6;
      this.ovalX = (window.innerWidth - this.ovalWidth) / 2;
      this.ovalY = (window.innerHeight - this.ovalHeight) / 2;

      if (isLandscape) {
        this.showLandscapeWarning = true;
      } else {
        this.showLandscapeWarning = false;
        this.drawOverlay();
      }
    },
    drawOverlay() {
      const canvas = this.$refs.overlay;
      const context = canvas.getContext('2d');

      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      context.clearRect(0, 0, canvas.width, canvas.height);
      context.fillStyle = 'rgba(0, 0, 0, 0.5)';
      context.fillRect(0, 0, canvas.width, canvas.height);

      context.globalCompositeOperation = 'destination-out';
      context.beginPath();
      context.ellipse(this.ovalX + this.ovalWidth / 2, this.ovalY + this.ovalHeight / 2, this.ovalWidth / 2, this.ovalHeight / 2, 0, 0, 2 * Math.PI);
      context.fill();

      context.globalCompositeOperation = 'source-over';
      context.strokeStyle = this.borderColor; // Use the dynamic border color
      context.lineWidth = 4;
      context.setLineDash([5, 5]);
      context.stroke();
    },
    captureVideo() {
      if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
        this.mediaRecorder.stop();
      }
    },
    acceptVideo() {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach(track => track.stop());
      }
      this.$emit('videoAccepted', this.capturedVideo);
      this.capturedVideo = null;
    },
    retryVideo() {
      this.capturedVideo = null;
      this.startCamera();
    },
    setupMediaRecorder(stream) {
      this.mediaRecorder = new MediaRecorder(stream);
      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.recordedChunks.push(event.data);
        }
      };

      this.mediaRecorder.onstop = async () => {
        const blob = new Blob(this.recordedChunks, { type: 'video/webm' });
        const reader = new FileReader();
        reader.onloadend = async () => {
          const base64data = reader.result;

          this.videoRecorded(base64data, () => {
            this.incrementInstructionIndex();
          }, () => {
            this.instructionText = 'Não foi possível enviar os documentos e vídeo da prova de vida, tente novamente.'
            this.uploadErrorFlag = true;
          })
        };
        reader.readAsDataURL(blob);
      };

      this.mediaRecorder.start();
      this.captureSelfie();
    },
    captureSelfie(callbackSuccess, callbackError) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const video = this.$refs.video;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const dataUrl = canvas.toDataURL('image/png');
      this.$emit('selfieCaptured', dataUrl, callbackSuccess, callbackError);
    },
    async nextInstruction() {

      if (this.type === 'doc-photo') {
        this.captureSelfie(() => {}, () => {
            this.instructionText = 'Não foi possível enviar os documentos e a self (foto do face), tente novamente.'
            this.uploadErrorFlag = true;
          });
        return;
      }

      if (this.currentInstructionIndex < this.instructions.length) {
        if (this.currentInstructionIndex === 3 && this.uploadErrorFlag) {

          this.retryUpload(() => {
            this.incrementInstructionIndex();
          }, () => {
            this.uploadErrorFlag = true;
          })

          return;
        }

        if (this.currentInstructionIndex === 3 && !this.uploadErrorFlag) {
          this.captureVideo();
          return;
        }

        if (this.currentInstructionIndex === -1) {
          this.setupMediaRecorder(this.videoStream);
        }

        this.incrementInstructionIndex();
      }
    },
    incrementInstructionIndex() {
      this.currentInstructionIndex++;
      this.instructionText = this.instructions[this.currentInstructionIndex];
      this.buttonText = this.currentInstructionIndex === this.instructions.length - 1 ? 'Finalizar' : 'Avançar';
    },
    async checkPermissions() {
      try {
        const cameraPermission = await navigator.permissions.query({ name: 'camera' });
        const microphonePermission = await navigator.permissions.query({ name: 'microphone' });

        if (cameraPermission.state !== 'granted') { //|| microphonePermission.state !== 'granted'
          alert('Permissões para acessar câmera ou microfone não concedidas. Por favor, autorize o uso.');
          return false;
        }
        return true;
      } catch (err) {
        console.error('Erro ao verificar permissões: ', err);
        return false;
      }
    },
  }
};
</script>

<style scoped>
.camera-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  overflow: hidden;
}

video {
  width: 100vh;
  height: 100vh;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.instructions {
  position: absolute;
  top: 10%;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  z-index: 10;
}

.capture-button {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  cursor: pointer;
  padding: 32px 32px;
}

.capture-button > i {
  font-size: 30px;
}
</style>
