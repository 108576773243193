<template>
  <div>
    <DocumentCapture
      v-if="shouldShowDocumentCapture"
      :key="captureKey"
      :isFront="isFront"
      :photoAccepted="handlePhotoAccepted"/>

    <SelfCapture
      v-if="shouldShowSelfCapture"
      :key="captureSelfKey"
      @selfieCaptured="selfieCaptured"/>
<!--
    <LifeProofCapture
      v-if="shouldShowLifeProofCapture"
      :key="key"
      :type="type"
      :videoRecorded="videoRecorded"
      :retryUpload="retryUpload"
      @selfieCaptured="selfieCaptured"
      @finish="completeOnboarding"/>
      -->

    <DocumentCaptureFinished v-if="showDocumentCaptureFinished" />
  </div>
</template>

<script>
import DocumentCapture from './DocumentCapture.vue';
import SelfCapture from './SelfCapture.vue';
import LifeProofCapture from './LifeProofCapture.vue';
import DocumentCaptureFinished from './DocumentCaptureFinished.vue';
import DocumentPersonService from '@/services/api/DocumentPersonService';

export default {
  components: {
    DocumentCapture,
    SelfCapture,
    LifeProofCapture,
    DocumentCaptureFinished
  },
  props: {
    type: {
      type: String,
      default: 'both',
    },
    token: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      key: 0,
      document: {
        photo_front: '',
        photo_back: '',
        photo_face: '',
        video: '',
      },
      service: {
        service: null
      },
      isFront: true,
      captureSelfKey: 0,
      captureKey: 0,
      documentCompleted: false,
      selfCompleted: false,
      liveProofCompleted: false,
      videoUploadError: false
    }
  },
  computed: {
    shouldShowDocumentCapture() {
      return (this.type === 'both' || this.type === 'doc-photo') && !this.documentCompleted
    },
    shouldShowSelfCapture() {
      return (this.type === 'both' || this.type === 'doc-photo') && (this.documentCompleted && !this.selfCompleted)
    },
    shouldShowLifeProofCapture() {
      return (this.type === 'both' || this.type === 'liveness-video') && !this.liveProofCompleted
    },
    showDocumentCaptureFinished() {
      if(this.type === 'both' || this.type === 'liveness-video'){
        return (this.documentCompleted && this.selfCompleted) && this.liveProofCompleted
      }else if(this.type === 'doc-photo'){
       return (this.documentCompleted && this.selfCompleted)
      }
      return false
    }
  },
  methods: {
    async sendDocument(callbackSuccess, callbackError) {
      this.$vs.loading();
      try {
        await this.service.uploadDocument(this.type, this.token, this.document);

        this.$vs.loading.close();

        if (this.type === 'both' || this.type === 'doc-photo') {
          this.documentCompleted = true
          this.selfCompleted = true
        }

        if (this.type === 'both' || this.type === 'liveness-video') {
          this.liveProofCompleted = true
        }

        //if (callbackSuccess) callbackSuccess();
      } catch (error) {
        this.$vs.loading.close();
        this.videoUploadError = true
        this.documentCompleted = false
        this.selfCompleted = false
        this.clearData()
        this.notifyError(this.$vs, this.$t('document_person.send_doc_error'), 5000, 'top-center')
        //if (callbackError) callbackError();
      }
    },
    clearData() {
      this.document.photo_front = null
      this.document.photo_back = null
      this.isFront = true //default
      this.document.photo_face = null
      this.document.video = null
    },
    handlePhotoAccepted(image, callbackSuccess, callbackError) {
      if (this.isFront) {
        this.document.photo_front = image;
        this.isFront = false;
      } else {
        this.document.photo_back = image;
      }

      if (this.document.photo_front && this.document.photo_back) {
        this.documentCompleted = true;
      }

      this.captureKey += 1;
    },
    handleLifeProofAccepted(image) {
      //console.log('Life proof accepted:', image);
    },
    videoRecorded(video, callbackSuccess, callbackError) {
      this.document.video = video;
      if (this.type === 'both' || this.type === 'liveness-video') {
        this.sendDocument(callbackSuccess, callbackError);
      }
    },
    selfieCaptured(photo_face, callbackSuccess, callbackError) {
      this.document.photo_face = photo_face;
      //console.log('Photo face captured:', photo_face);
      if (this.document.photo_face) {
        this.sendDocument(callbackSuccess, callbackError)
      }
    },
    async retryUpload(callbackSuccess, callbackError) {
      if (this.videoUploadError) {
        this.videoUploadError = false;
        await this.sendDocument(callbackSuccess, callbackError);
      }
    },
    completeOnboarding() {
      this.$router.push('/document_capture_finished');
    }
  },
  beforeMount() {
    this.service = DocumentPersonService.build(this.$vs);
  }
}
</script>
