var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "cameraContainer", staticClass: "camera-view" },
    [
      _c(
        "vs-popup",
        {
          attrs: {
            active: _vm.showHelpModal,
            title: "Instruções",
            "button-close-hidden": true,
          },
          on: {
            "update:active": function ($event) {
              _vm.showHelpModal = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("img", {
                staticClass: "mx-auto",
                staticStyle: { "max-width": "90px" },
                attrs: { src: _vm.getImageSrc, alt: "document" },
              }),
              _c("h2", { staticClass: "text-lg font-semibold mb-2" }, [
                _vm._v(
                  _vm._s(_vm.isFront ? "Documento Frente" : "Documento Verso")
                ),
              ]),
              _vm.showPortraitWarning
                ? _c("p", { staticClass: "text-danger font-semibold mb-1" }, [
                    _vm._v(
                      "A captura de documento deve ser feita em modo paisagem."
                    ),
                  ])
                : _vm._e(),
              _c("p", { staticClass: "text-gray-600 mb-4" }, [
                _vm._v(
                  "Posicione o documento em uma mesa, centralize-o na marcação e realize a captura da foto."
                ),
              ]),
              _c(
                "vs-button",
                {
                  staticClass: "px-4 py-2",
                  attrs: { color: "success" },
                  on: {
                    click: function ($event) {
                      _vm.showHelpModal = false
                    },
                  },
                },
                [_vm._v(" OK, entendi ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "vs-popup",
        {
          ref: "popupWarning",
          attrs: {
            active: _vm.showPortraitWarning,
            title: "Atenção",
            "button-close-hidden": true,
          },
        },
        [
          _c("div", { staticClass: "text-center" }, [
            _c("p", { staticClass: "text-gray-600 mb-4" }, [
              _vm._v(
                "A captura de documento deve ser feita em modo paisagem. Por favor, vire o dispositivo."
              ),
            ]),
          ]),
        ]
      ),
      _vm.capturedImage && !_vm.showPortraitWarning
        ? _c("div", { staticClass: "preview grid grid-cols-3 gap-4" }, [
            _c("div", { staticClass: "col-span-2" }, [
              _c("img", {
                staticClass: "mx-auto",
                staticStyle: { "max-width": "100%" },
                attrs: { src: _vm.capturedImage, alt: "captured document" },
              }),
            ]),
            _c(
              "div",
              { staticClass: "flex flex-col justify-center items-center" },
              [
                _c("p", { staticClass: "text-gray-600 mb-4" }, [
                  _vm._v("A foto está legível?"),
                ]),
                _c(
                  "vs-button",
                  {
                    staticClass: "px-4 py-2",
                    attrs: { color: "success" },
                    on: { click: _vm.acceptPhoto },
                  },
                  [_vm._v(" Sim, prosseguir ")]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "px-4 py-2 mt-2",
                    attrs: { color: "danger" },
                    on: { click: _vm.retryPhoto },
                  },
                  [_vm._v(" Não, tentar novamente ")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("video", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.capturedImage && !_vm.showPortraitWarning,
            expression: "!capturedImage && !showPortraitWarning",
          },
        ],
        ref: "video",
        staticClass: "video",
        attrs: { autoplay: "", playsinline: "" },
      }),
      _c("canvas", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.capturedImage && !_vm.showPortraitWarning,
            expression: "!capturedImage && !showPortraitWarning",
          },
        ],
        ref: "overlay",
        staticClass: "overlay",
      }),
      _c("vs-button", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.capturedImage && !_vm.showPortraitWarning,
            expression: "!capturedImage && !showPortraitWarning",
          },
        ],
        staticClass: "capture-button",
        attrs: { color: "primary", icon: "camera" },
        on: { click: _vm.capturePhoto },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }