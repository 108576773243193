import { render, staticRenderFns } from "./SelfCapture.vue?vue&type=template&id=953e26ce&scoped=true&"
import script from "./SelfCapture.vue?vue&type=script&lang=js&"
export * from "./SelfCapture.vue?vue&type=script&lang=js&"
import style0 from "./SelfCapture.vue?vue&type=style&index=0&id=953e26ce&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "953e26ce",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/danyelsanches/Documents/projetos/educatena/educatena-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('953e26ce')) {
      api.createRecord('953e26ce', component.options)
    } else {
      api.reload('953e26ce', component.options)
    }
    module.hot.accept("./SelfCapture.vue?vue&type=template&id=953e26ce&scoped=true&", function () {
      api.rerender('953e26ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/apps/document/SelfCapture.vue"
export default component.exports